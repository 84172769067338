import * as React from 'react';
import { CookieSetting } from './config';
import Switch from "./switch"

type CookieConsentSettingsProps = {
    title: string,
    settingsLabel: string,
    settings: Array<CookieSetting>,
    alwaysActiveLabel: string,
    acceptBtnTitle: string,
    acceptAllHandler: () => void,
    savePreferences: (settings: object) => void,
    theme?: string,
}

export const CookieConsentSettings: React.FC<CookieConsentSettingsProps> = (props: CookieConsentSettingsProps) => {

    const preferences: object = {};
    const renderCategoryInfo = (item:any, _index:any) => {
        return <div className='cookie-consent-type-info'>{item.info}</div>
    }

    const renderSwitchOrRequired = (item: CookieSetting, index:number) => {
        if (item.required) {
            return <span className='co-consent-required'>{props.alwaysActiveLabel}</span>
        } else {
            return <Switch onClick={switchClickHandler} checked={true} index={index} id={item.id} />;
        }
    }

    const switchClickHandler = (id: string, value: boolean) => {
        // @ts-ignore
        preferences[id] = value;
    }

    return (
        <div className={`cookie-consent-settings`} title={props.title}>
            <div className="cookie-consent-settings-title">{props.settingsLabel}</div>
            <div className='cookie-consent-types-wrap'>
                <ul className='cookie-consent-types'>
                    {props.settings.map((item: CookieSetting, index: number) => {
                        return <li key={`category-${index}`}>
                            <div className='co-consent-label-and-switch-wrap'>
                                <div className='co-consent-label'>{item.categoryLabel}</div>
                                {renderSwitchOrRequired(item, index)}
                            </div>
                            {renderCategoryInfo(item, index)}
                            <div className='co-consent-category-divider'></div>
                        </li>
                    })}
                </ul>
                <div className="cookie-consent-controls">
                    <button className='cookie-consent-btn cookie-consent-controls-button-save' onClick={() => props.savePreferences(preferences)}>Запази</button>
                    <button className='cookie-consent-btn cookie-consent-controls-button-accept' onClick={props.acceptAllHandler}>
                        {props.acceptBtnTitle}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CookieConsentSettings;
