import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CookieConsent from './cookie-consent/cookie-consent';
import CookieConsentConfig from './cookie-consent/config';

const cConsent = (cookiesConfig: CookieConsentConfig) => {
    const elem = document.createElement("div");
    document.body.appendChild(elem);
    ReactDOM.render(
        <CookieConsent {...cookiesConfig} />,
        elem
    );
}

export default cConsent;
