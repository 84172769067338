export const saveConsentSettings = (preferences: object) => {
    const prefs: any = {};
    prefs['set'] = true;
    prefs['categories'] = preferences;
    console.log(prefs);
    setCookie('coconsent', JSON.stringify(prefs), 7);
}

const setCookie = (name: string, value: string, days: number) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const checkForSettingsSetAlready = () => {
    const cookieValue = JSON.parse(getCookie('coconsent'));
    if (cookieValue && cookieValue.hasOwnProperty('set')) {
        return cookieValue.set;
    }
    return false;
}

export const getPrefsCategories = () => {
    if (checkForSettingsSetAlready) {
        const cookieValue = JSON.parse(getCookie('coconsent'));
        return cookieValue['categories']
    }
    return {};
}

const getCookie = (name: string) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
