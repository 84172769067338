import * as React from 'react';
import CookieConsentSettings from './settings';
import CookieConsentConfig from './config';
import {saveConsentSettings, checkForSettingsSetAlready, getPrefsCategories} from './cookie.utils';

export const CookieConsent: React.FC<CookieConsentConfig> = (props: CookieConsentConfig) => {
    const consentSettingsSetAlready = checkForSettingsSetAlready();
    React.useEffect(() => {
        if (consentSettingsSetAlready) {
            // If the consent settings are already set, we need to run the savePreferences
            // once so it will trigger the onDeny for the specific function
            savePreferences(getPrefsCategories());
        }
    }, []);

    const [accepted, setAccepted] = React.useState(consentSettingsSetAlready);
    const [settingsActive, setSettingsActive] = React.useState(false);

    const description = props.cookiesDescription;
    const settingsBtnTitle = props.settingsLabel;
    const acceptBtnTitle = props.acceptAllLabel;

    const settingsHandler = () => {
        setSettingsActive(true);
    }

    const acceptAllHandler = () => {
        // Just hide it and update some cookie so we don't show it for some time
        // How long - 7 days?
        const preferences: any = {};
        for (let setting of props.cookieSettings) {
            if (!setting.required) {
                preferences[setting.id] = true;
            }
        }
        saveConsentSettings(preferences);
        setAccepted(true);
    }

    const savePreferences = (prefs: object) => {
        for (let setting of props.cookieSettings) {
            // @ts-ignore
            if (prefs[setting.id] !== "undefined") {
                // @ts-ignore
                if (prefs[setting.id] === false) {
                    setting.onDeny();
                }
            }
        }
        saveConsentSettings(prefs);
        setAccepted(true);
    }

    const renderSettings = () => {
        return <CookieConsentSettings
            title={props.title}
            settingsLabel={props.settingsLabel}
            alwaysActiveLabel={props.alwaysActiveLabel}
            settings={props.cookieSettings}
            acceptAllHandler={acceptAllHandler}
            savePreferences={savePreferences}
            acceptBtnTitle={acceptBtnTitle} />;
    }

    const renderDefault = () => {
        return <div>
            <div className="cookie-consent-title">{props.title}</div>
            <div className='cookie-consent-body-wrap'>
                <div className='cookie-consent-info-wrap'>
                    <div className="cookie-consent-description">
                        {description}
                    </div>
                </div>
                <div className="cookie-consent-controls">
                    <button className='cookie-consent-btn cookie-consent-controls-button-accept' onClick={acceptAllHandler}>
                        {acceptBtnTitle}
                    </button>
                    <button className='cookie-consent-btn cookie-consent-controls-button-settings' onClick={settingsHandler}>
                        {settingsBtnTitle}
                    </button>
                </div>
            </div>
        </div>
    }

    if (accepted) {
        return <></>;
    } else {
        return (
            <div className="co-consent-wrap">
                <div title={props.title}>
                    <div className='cookie-consent'>
                        <div className='cookie-consent-inner-wrap'>
                            {settingsActive ? renderSettings() : renderDefault()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookieConsent;
