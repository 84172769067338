import * as React from 'react';

type SwitchProps = {
    id: string;
    index: number,
    checked: boolean,
    onClick: (id: string, value: boolean) => void,
}

export const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {

    const [checked, setChecked] = React.useState(props.checked);

    const onClickHandler = () => {
        setChecked(!checked);
        /*
        if (checked) {
            setChecked(false);
        } else {
            setChecked(true);
        }*/
        props.onClick(props.id, !checked);
    }

    return (
        <label onMouseDown={onClickHandler} className="switch">
            <input type="checkbox" checked={checked} />
            <span className="slider round"></span>
        </label>
    );
}

export default Switch;
